<template>
  <div class="container-fluid">
    <div class="card-header top-bar">
      <div class="header-left">
        <div class="d-flex">
          <div class="admin-search-box">
            <SfInputSearch
              placeholder="Search"
              v-debounce:500ms="searchInputChange"
              class="sf-input-search"
            />
          </div>
          <div class="app-version ml-3">
            <div class="sf-select-group mb-3">
              <Select2
                class="sf-select2"
                v-model="filterByOrganization"
                :options="organizationOptions"
                @select="loadPlayer"
              />
              <span class="lb-right caret"></span>
            </div>
          </div>
          <div class="app-version ml-3">
            <div class="sf-select-group mb-3">
              <Select2
                class="sf-select2"
                :options="application_installed"
                v-model="filterByApplication"
                @select="filterByVersion($event)"
              />
              <span class="lb-right caret"></span>
            </div>
          </div>
          <div class="app-version ml-3">
            <div class="sf-select-group mb-3">
              <Select2
                class="sf-select2"
                :options="listHardwareOptions"
                v-model="filterByHardware"
                @select="filterByHardwareType($event)"
              />
              <span class="lb-right caret"></span>
            </div>
          </div>
          <div class="app-version ml-3">
            <p class="couter-player">
              {{ playersChoosen.length }}/{{ formatQuantity(total_records, 'player') }} selected for
              update
            </p>
          </div>
        </div>
      </div>
      <div class="header-right">
        <button class="sf-primary-add" @click="showModalUpdate()">Update players</button>
      </div>
    </div>

    <div class="row mb-3">
      <div v-if="!isEmpty" class="col-12 d-flex justify-content-end">
        <b-pagination
          class="sf-b-pagination"
          :total-rows="limitPage.value * pagination.total"
          :per-page="limitPage.value"
          @change="changePage"
          :limit="6"
          v-model="currentPage"
        />
        <multiselect
          style="width: 105px"
          class="mx-0 sf-multiselect"
          v-model="limitPage"
          :options="[
            {
              name: `25 / Page`,
              value: '25'
            },
            {
              name: `50 / Page`,
              value: '50'
            },
            {
              name: `100 / Page`,
              value: '100'
            }
          ]"
          :searchable="false"
          :allow-empty="false"
          deselectLabel=""
          selectLabel=""
          selectedLabel=""
          label="name"
          track-by="name"
        >
        </multiselect>
      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="sf-table col-12">
          <b-table
            show-empty
            striped
            hover
            :items="allPlayerAdmin"
            :fields="fields"
            head-variant="none"
            table-variant="none"
            thead-class="custom-b-table"
            :busy="loading"
          >
            <template v-slot:table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
            <template v-slot:empty>
              <p class="d-flex justify-content-center">No data available in table</p>
            </template>
            <template v-slot:head(status)="field">{{ field.label }}</template>

            <template v-slot:head(checkbox)="field">
              <label class="sf-checkbox">
                <input type="checkbox" @click="checkAllPlayer" v-model="allSelected" />
                <span class="checkmark"></span>
              </label>
            </template>
            <template v-slot:cell(checkbox)="field">
              <label class="sf-checkbox">
                <input type="checkbox" :value="field.item" v-model="playersChoosen" />
                <span class="checkmark"></span>
              </label>
            </template>

            <template v-slot:head(organization)="field">
              <span>{{ field.label }}</span>
              <span class="custome-arrows"></span>
            </template>
            <template v-slot:cell(organization)="field">
              <p v-if="field.item.organization">
                <router-link :to="`/admin/clients/organizations/${field.item.organization.id}`">{{
                  field.item.organization.name
                }}</router-link>
              </p>
            </template>

            <template v-slot:head(name)="field">
              <span>{{ field.label }}</span>
              <span class="custome-arrows"></span>
            </template>
            <template v-slot:cell(name)="field">
              <p>
                <router-link
                  :to="`/admin/clients/players/${field.item.id}`"
                  :class="{ 'text-color-grey-40': !field.item.activated }"
                  >{{ field.item.name }}</router-link
                >
              </p>
            </template>

            <template v-slot:head(hardware_type)="field">
              <span>{{ field.label }}</span>
              <span class="custome-arrows"></span>
            </template>
            <template v-slot:cell(hardware_type)="field">
              <p>{{ field.item.hardware_type.displayname }}</p>
            </template>

            <template v-slot:cell(update_status)="field">
              <div class="sf-table-status" :class="updateStatus(field.item)"></div>
            </template>

            <template v-slot:head(target_version)="field">
              <span>{{ field.label }}</span>
              <span class="custome-arrows"></span>
            </template>
            <template v-slot:cell(target_version)="field">
              <p v-if="field.item.target_version">
                {{
                  `${field.item.target_version.version} (${field.item.target_version.version_code})`
                }}
              </p>
            </template>

            <template v-slot:head(application_version)="field">
              <span>{{ field.label }}</span>
              <span class="custome-arrows"></span>
            </template>
            <template v-slot:cell(application_version)="field">
              <p v-if="field.item.application_code">
                {{ `${field.item.application_version} (${field.item.application_code})` }}
              </p>
              <p v-else-if="field.item.application_version">
                {{ `${field.item.application_version}` }}
              </p>
            </template>

            <template v-slot:head(running_campaign)="field">
              <span>{{ field.label }}</span>
              <span class="custome-arrows"></span>
            </template>
            <template v-slot:cell(running_campaign)="field">
              <p>{{ field.item.running_campaign }}</p>
            </template>

            <template v-slot:head(last_connection)="field">
              <span>{{ field.label }}</span>
              <span class="custome-arrows"></span>
            </template>
            <template v-slot:cell(last_connection)="field">
              <p v-if="field.item.last_connection && field.item.activated">
                {{ timeSince(field.item.last_connection) }}
              </p>
            </template>
          </b-table>
        </div>
      </div>
    </div>

    <SfModal
      :title="'Confirm upgrade players'"
      :icon="'fa fa-user'"
      :width="450"
      closeOnOverlay
      :show.sync="isShownModalConfirm"
    >
      <div class="content-delete mb-3">
        Doing this will update {{ formatQuantity(getPlayerIds().length, 'player') }} to version
        {{ targetVersion.version }} ({{ targetVersion.version_code }}) across
        {{ formatQuantity(getOrganizationIds().length, 'organization') }} . Are you sure you want to
        proceed?
      </div>

      <div class="sf-modal-footer">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="sf-modal-action">
                <button class="sf-secondary" type="button" @click="isShownModalConfirm = false">
                  Cancel
                </button>
                <button class="sf-primary" @click="updateApplicationPlayers">Update</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SfModal>

    <SfModal
      :title="'Upgrade players'"
      :icon="'fa fa-user'"
      :width="1000"
      closeOnOverlay
      :show.sync="isShownModalUpdate"
    >
      <div class="sf-modal-body">
        <div class="container">
          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Application Version</label>
                </div>
                <div class="sf-select-group">
                  <Select2
                    class="sf-select2"
                    @select="changePiFirmware"
                    :options="all_pi_firmware"
                  />
                  <span class="lb-right caret"></span>
                </div>
              </div>
            </div>
            <div class="sf-table col-12 mt-3">
              <b-table
                show-empty
                striped
                hover
                :items="playersChoosen"
                :fields="fieldsTableModal"
                :per-page="5"
                :current-page="currentPageChosen"
                head-variant="none"
                table-variant="none"
                thead-class="custom-b-table"
                id="chosenTable"
                :tbody-tr-class="displayCanUpgrade"
              >
                <template v-slot:head(organization)="field">
                  <span>{{ field.label }}</span>
                  <span class="custome-arrows"></span>
                </template>
                <template v-slot:cell(organization)="field">
                  <p v-if="field.item.organization">{{ field.item.organization.name }}</p>
                </template>

                <template v-slot:head(name)="field">
                  <span>{{ field.label }}</span>
                  <span class="custome-arrows"></span>
                </template>
                <template v-slot:cell(name)="field">
                  <p>{{ field.item.name }}</p>
                </template>

                <template v-slot:head(target_version)="field">
                  <span>{{ field.label }}</span>
                  <span class="custome-arrows"></span>
                </template>
                <template v-slot:cell(target_version)="field">
                  <p v-if="field.item.target_version">
                    {{
                      `${field.item.target_version.version} (${field.item.target_version.version_code})`
                    }}
                  </p>
                </template>

                <template v-slot:head(application_version)="field">
                  <span>{{ field.label }}</span>
                  <span class="custome-arrows"></span>
                </template>
                <template v-slot:cell(application_version)="field">
                  <p v-if="field.item.application_code">
                    {{ `${field.item.application_version} (${field.item.application_code})` }}
                  </p>
                  <p v-else-if="field.item.application_version">
                    {{ `${field.item.application_version}` }}
                  </p>
                </template>
              </b-table>
            </div>
            <div class="my-3">
              <b-pagination
                class="sf-b-pagination"
                :total-rows="playersChoosen.length"
                :per-page="5"
                :limit="6"
                v-model="currentPageChosen"
                aria-controls="chosenTable"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="footer-button mt-3">
        <button class="sf-secondary" @click="isShownModalUpdate = false">Cancel</button>
        <button class="sf-primary" @click="showModalConfirm">Save</button>
      </div>
    </SfModal>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import SfModal from '@/components/SfModal.vue'
import { timeSince, formatQuantity } from '@/helper/helper'
import SfInputSearch from '@/components/SfInputSearch.vue'
import Select2 from 'v-select2-component'
import AdminFirmWareService from '@/services/admin-firmware.service'
import Multiselect from 'vue-multiselect'
import AdminHardwareService from '@/services/admin-hardware.service'

export default {
  name: 'admin-player',
  components: {
    SfModal,
    SfInputSearch,
    Select2,
    Multiselect
  },
  data() {
    return {
      isShownModalUpdate: false,
      currentPage: 1,
      currentPageChosen: 1,
      fields: [
        {
          key: 'checkbox',
          label: '',
          colType: 'checkbox',
          class: 'sf-table-header-status'
        },
        {
          key: 'name',
          sortable: true,
          colType: 'name',
          label: 'Name'
        },
        {
          key: 'hardware_type',
          sortable: true,
          colType: 'hardware-type',
          label: 'Hardware Type'
        },
        {
          key: 'update_status',
          sortable: true,
          colType: 'update_status',
          label: 'Update status'
        },
        {
          key: 'target_version',
          sortable: true,
          colType: 'target_version',
          label: 'Target version',
          headerTitle: 'The application version that the player is in progress of updating to.'
        },
        {
          key: 'application_version',
          sortable: true,
          colType: 'application_version',
          label: 'Current version',
          headerTitle: 'The application version that was last reported by the player.'
        },
        {
          key: 'organization',
          sortable: true,
          colType: 'organization',
          label: 'Organization'
        },
        {
          key: 'running_campaign',
          sortable: true,
          colType: 'running_campaign',
          label: 'Running campaign'
        },
        {
          key: 'last_connection',
          sortable: true,
          colType: 'last_connection',
          label: 'Last connection'
        }
      ],
      fieldsTableModal: [
        {
          key: 'name',
          sortable: true,
          colType: 'name',
          label: 'Name'
        },
        {
          key: 'target_version',
          sortable: true,
          colType: 'target_version',
          label: 'Target Version'
        },
        {
          key: 'application_version',
          sortable: true,
          colType: 'application_version',
          label: 'Current Version'
        },
        {
          key: 'organization',
          sortable: true,
          colType: 'organization',
          label: 'Organization'
        }
      ],
      dataSearch: '',
      loadingPlayer: false,
      playersChoosen: [],
      allSelected: false,
      targetVersion: {
        id: '',
        version: '',
        version_code: ''
      },
      filterByOrganization: '0',
      filterByApplication: '0',
      filterByHardware: '1',
      optionFilterByVersion: {
        version: '',
        version_code: ''
      },
      isShownModalConfirm: false,
      limitPage: {
        name: '25 / Page',
        value: '25'
      },
      listHardwareOptions: []
    }
  },

  methods: {
    timeSince,
    formatQuantity,
    getAllPlayersAdmin(
      page,
      limit,
      search,
      organization,
      applicationVersion,
      applicationCode,
      filterByHardware
    ) {
      filterByHardware = filterByHardware === '1' ? '' : filterByHardware
      organization = organization === '0' ? '' : organization
      applicationVersion = applicationVersion === '0' ? '' : applicationVersion
      this.$store.dispatch('adminplayer/getAllPlayersAdmin', {
        page,
        limit,
        search,
        organization,
        applicationVersion,
        applicationCode,
        filterByHardware
      })
    },
    getAllApplicationVersion() {
      this.$store.dispatch('adminplayer/getAllApplicationVersion')
    },
    getAllFirmwaresNoPaging(activePage, search) {
      this.$store.dispatch('adminfirmware/getAllFirmwaresNoPaging')
    },
    getListOrganization() {
      this.$store.dispatch('adminuser/getAllOrganizationNoPaging')
    },
    searchInputChange(value) {
      this.dataSearch = value
      this.currentPage = 1
      this.loadPlayer()
    },
    changePage(page) {
      this.allSelected = false
      this.currentPage = page
      this.loadPlayer()
    },
    loadPlayer() {
      this.getAllPlayersAdmin(
        this.currentPage,
        this.limitPage.value,
        this.dataSearch,
        this.filterByOrganization,
        this.optionFilterByVersion.version,
        this.optionFilterByVersion.version_code,
        this.filterByHardware
      )
    },
    showModalUpdate() {
      if (this.playersChoosen.length === 0) {
        this.$toast.error('Please choose any player.')
        return
      }
      this.isShownModalUpdate = true
    },
    showModalConfirm() {
      if (this.targetVersion.id === '') {
        this.$toast.error('Please choose any version.')
        return
      }
      this.isShownModalUpdate = false
      this.isShownModalConfirm = true
    },
    updateApplicationPlayers() {
      AdminFirmWareService.upgradePlayers(this.targetVersion.id, {
        player_ids: this.getPlayerIds()
      })
        .then((response) => {
          this.isShownModalConfirm = false
          this.$toast.success('Players upgrade.')
          this.loadPlayer()
          this.allSelected = false
          this.playersChoosen = []
        })
        .catch((err) => {
          this.$toast.error(err.message)
        })
    },
    conditionUpgrade(item) {
      if (this.targetVersion.id) {
        if (
          item.target_version &&
          parseInt(this.targetVersion.id) === parseInt(item.target_version.id)
        ) {
          return false
        }
        if (item.application_version) {
          const version = item.target_version
            ? item.target_version.version
            : item.application_version
          if (version) {
            if (item.application_code) {
              return (
                this.versionCompare(version, this.targetVersion.version) <= 0 &&
                parseInt(this.targetVersion.version_code) > parseInt(item.application_code)
              )
            }
            return this.versionCompare(version, this.targetVersion.version) <= 0
          }
          return true
        } else {
          return true
        }
      }
    },
    versionCompare(v1, v2, options) {
      const lexicographical = options && options.lexicographical
      const zeroExtend = options && options.zeroExtend
      let v1parts = v1.split('.')
      let v2parts = v2.split('.')

      function isValidPart(x) {
        return (lexicographical ? /^\d+[A-Za-z]*$/ : /^\d+$/).test(x)
      }

      if (!v1parts.every(isValidPart) || !v2parts.every(isValidPart)) {
        return NaN
      }

      if (zeroExtend) {
        while (v1parts.length < v2parts.length) v1parts.push('0')
        while (v2parts.length < v1parts.length) v2parts.push('0')
      }

      if (!lexicographical) {
        v1parts = v1parts.map(Number)
        v2parts = v2parts.map(Number)
      }

      for (let i = 0; i < v1parts.length; ++i) {
        if (v2parts.length === i) {
          return 1
        }

        if (v1parts[i] === v2parts[i]) {
          continue
        } else if (v1parts[i] > v2parts[i]) {
          return 1
        } else {
          return -1
        }
      }

      if (v1parts.length !== v2parts.length) {
        return -1
      }
      // value -1: v1 < v2
      return 0
    },
    filterByVersion(value) {
      this.optionFilterByVersion.version = value.version ? value.version : ''
      this.optionFilterByVersion.version_code = value.version_code ? value.version_code : ''
      this.loadPlayer()
    },
    filterByHardwareType(value) {
      this.loadPlayer()
    },
    changePiFirmware(value) {
      this.targetVersion.id = value.id
      this.targetVersion.version = value.version
      this.targetVersion.version_code = value.version_code
    },
    updateStatus(item) {
      if (item.target_version) {
        if (item.application_version) {
          if (
            this.versionCompare(item.target_version.version, item.application_version) === 0 &&
            parseInt(item.target_version.version_code) === parseInt(item.application_code)
          ) {
            return 'active'
          } else if (
            this.versionCompare(item.target_version.version, item.application_version) > 0 ||
            parseInt(item.target_version.version_code) > parseInt(item.application_code)
          ) {
            if (
              item.player_log &&
              item.player_log.target_version ===
                `${item.target_version.version} (${item.target_version.version_code})` &&
              !item.player_log.status
            ) {
              return 'inactive'
            }
          }
        }
        return 'pending'
      }
      return 'none'
    },
    checkAllPlayer(value) {
      this.allSelected = value.target.checked
      if (this.allSelected) {
        this.playersChoosen = [...this.playersChoosen, ...this.allPlayerAdmin]
        this.playersChoosen = [...new Set(this.playersChoosen)]
      } else {
        this.playersChoosen = this.playersChoosen.filter(
          (player) => this.allPlayerAdmin.findIndex((x) => x.id === player.id) < 0
        )
      }
    },
    getPlayerIds() {
      const player_ids = []
      this.playersChoosen.map((player) => {
        if (this.conditionUpgrade(player)) {
          player_ids.push(player.id)
        }
      })
      return player_ids
    },
    getOrganizationIds() {
      const organization_ids = []
      this.playersChoosen.map((player) => {
        if (this.conditionUpgrade(player)) {
          if (organization_ids.includes(player.organization.id)) {
            return
          }
          organization_ids.push(player.organization.id)
        }
      })
      return organization_ids
    },
    displayCanUpgrade(item, type) {
      if (item && type === 'row') {
        if (!this.conditionUpgrade(item)) {
          return 'no-upgrade'
        }
      } else {
        return null
      }
    },
    getListHardwareType() {
      AdminHardwareService.getAllHardwaresNoPaging()
        .then((res) => {
          res.data.map((hardwareType) => {
            this.listHardwareOptions.push({
              id: hardwareType.id,
              text: hardwareType.displayname
            })
          })
        })
        .catch((err) => {
          this.$toast.error(err.message ? err.message : 'Something went wrong')
        })
    }
  },
  watch: {
    playersChoosen(value) {
      const playerChecked = value.filter(
        (player) => this.allPlayerAdmin.findIndex((x) => x.id === player.id) >= 0
      )
      this.allSelected = playerChecked.length === this.allPlayerAdmin.length
    },
    allPlayerAdmin(value) {
      const playerChecked = this.playersChoosen.filter(
        (player) => value.findIndex((x) => x.id === player.id) >= 0
      )
      this.allSelected = playerChecked.length === value.length
    },
    limitPage(data) {
      this.currentPage = 1
      this.loadPlayer()
    }
  },
  computed: {
    ...mapState('adminplayer', {
      allPlayerAdmin: 'allPlayerAdmin',
      pagination: 'pagination',
      loading: 'loading',
      error: 'error',
      application_installed: 'application_installed',
      total_records: 'total_records'
    }),
    ...mapGetters('adminplayer', {
      isEmpty: 'isEmpty'
    }),
    ...mapState('adminfirmware', {
      all_pi_firmware: 'all_pi_firmware'
    }),
    ...mapState('adminuser', {
      organizationOptions: 'organizationOptions'
    })
  },
  mounted() {
    this.loadPlayer()
    this.getAllFirmwaresNoPaging()
    this.getListOrganization()
    this.getAllApplicationVersion()
    this.getListHardwareType()
  }
}
</script>

<style lang="scss" scoped>
@import '../../Adminpage.scss';
.select2-container--default .select2-selection--single {
  background-color: #fff;
  border: 1px solid #aaa;
  border-radius: 4px;
  height: 35px !important;
}
.text-area-control {
  padding: 12px 0px;
  width: 90%;
  border: none;
  outline: none;
  height: 100px;
  max-height: 150px;
  min-height: 37px;
  margin: 1px;
}

.input-group-text-area {
  height: auto !important;
  .custom-input-group-text {
    align-items: flex-start;
    .input-group-text {
      height: 37px !important;
    }
  }
}
.app-version {
  width: 250px;
}
.couter-player {
  line-height: 34px;
}
.img-player {
  max-width: 20px;
  max-height: 20px;
}
.top-bar {
  height: 100%;
}
@media (max-width: 1075px) {
  .header-left .d-flex {
    flex-direction: column;
  }
  .admin-search-box {
    margin-left: 1rem;
    margin-bottom: 1rem;
  }
}
</style>
